header {
  display: flex;
  position: sticky;
  top: 0;
  background-color: white;
  height: 80px;
  box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
}

.left {
  margin-left: 10%;
  flex: 1;
  height: 100%;
}

.logo_img {
  height: 100%;
}

.right {
  display: flex;
  justify-content: flex-end;
  margin-right: 10%;
  flex: 1;
  height: 100%;
}