.paiSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    gap: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0.75 !important;
}

.left h1 {
    justify-content: center;
    color: black;
}

.left p {
    font-weight: 500;
}

.other {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.section01 {
    background-image: url("../../imagens/fundo-01.png");
    background-repeat: no-repeat;
    background-position: right;
    padding-top: 140px;
    padding-bottom: 150px;
    width: 100%;
    display: flex;
}

.section02 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: black;
}

.section02 div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0% 10% 0% 10%;
    font-weight: 500;
    padding-top: 100px;
    padding-bottom: 100px;
    gap: 2rem;
}

.titulo {
    font-weight: 700;
    color: black;
    padding-bottom: 5px;
    border-bottom: solid 2px #FC0004;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.section02 div p {
    text-align: justify;
}

.section02 div p strong {
    color: black;
}

.section03 {
    color: black;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 3rem;
    padding-top: 70px;
    padding-bottom: 3rem;
    background-image: url("../../imagens/fundo-02.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.section03 div {
    display: flex;
}

.rth_1548 {
    width: 100%;
    margin: 0% 10% 0% 10%s;
    align-items: center;
    display: flex;
    gap: 5rem;
}

.rth_1549 {
    color: black;
    gap: 2rem;
    display: flex;
    flex-direction: column;
}

.rth_1549 p {
    margin-top: -2rem;
}

.rth_1549 h3 {
    font-weight: 500;
    color: black;
}

.video {
    width: 600px;
    border-radius: 10px;
    overflow: hidden;
}

.logo{
    filter: none;
    height: 100px;
}