/* RODAPE */

*{
    margin: 0;
    list-style: none;
    text-decoration: none;
    color: #ffffff;
}

.rodape {
    background: linear-gradient(146.76deg, #ca0003 2.94%, #8d002a 114.88%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    width: 100%;
    color: #ffffff;
}

.Footer {
    display: flex;
    text-align: left;
    justify-content: space-around;
    width: 90%;
    padding: 20px;
}

.margin {
    width: 90%;
}

.textTituloFooter {
    font-weight: bold;
    font-size: 20px;
}

.titleFooter{
    margin-bottom: 1rem;
}

.textFooter {
    list-style: none;
    font-weight: normal;
    font-size: 15px;
    margin-top: .5rem;
    text-align: center;
}

button {
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 15px;
    cursor: pointer;
}

.copyright {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.copyright h4 {
    display: flex;
    border-top: solid 1px #ffffff;
    justify-content: center;
    padding-top: 20px;
    width: 100%;
    font-weight: 300;
    font-size: 14px;
}


