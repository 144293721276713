.otherButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.otherButton button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 140px;
    background: linear-gradient(146.76deg, #FC0004 2.94%, #BC0055 114.88%);
    border-radius: 4px;
    height: 35px;
    text-align: center;
}

.otherButton button:hover {
    background: linear-gradient(195.76deg, #FC0004 2.94%, #BC0055 114.88%);
    box-shadow: 0px 2px 5px -1px #00000075;
}