.botao {
    display: flex;
    align-items: center;
    height: 98%;
    padding-inline: 20px;
    font-weight: 700;
}

.is_active {
    border-bottom: solid 2px #FC0004;
}

p {
    color: black;
}